import axios from 'axios';
import React, { useEffect, useState } from 'react';

function ProductList() {
    const [products, setProducts] = useState([]);
    const [productsLoading, setProductsLoading] = useState(false);

    const getProducts = async () => {
        setProductsLoading(true);
        const productsUrl = 'https://devresearch.ainetwork.in:3007/api/anjani/getAllAnjaniProducts';

        try {
            const result = await axios.get(productsUrl);
            setProducts(result.data.data);
            console.log(result.data);
        } catch (error) {
            console.error("Error fetching products:", error);
        } finally {
            setProductsLoading(false);
        }
    };

    useEffect(() => {
        getProducts();
    }, []);

    return (
        <section className="products" id="products">
            <h2>Our Products</h2>
            {productsLoading ? (
                <p>Loading products...</p>
            ) : (
                <div className="product-cards">
                    {products.map((product, index) => (
                        <div className="product-card" key={index}>
                            <img src={product.imageUrl} alt={product.productName} className="product-image" />
                            <h3>{product.productName}</h3>
                            <p>{product.productDescription}</p>
                            <a href={product.extensionForWebsite} className="learn-more-btn">
                                Learn More
                            </a>
                        </div>
                    ))}
                </div>
            )}
        </section>
    );
}

export default ProductList;
