import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomePage from '../HomePage/HopePage';
import AiClinicsMainPage from '../ProductsDescription/AiClinics/AiclinicsMainPage';
import AiResearchMainPage from '../ProductsDescription/Ai Research/AiResearchMainPage';
import GdProMainPage from '../ProductsDescription/GdPro/GdProMainPage';


function RoutingPage() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/aiclinics" element={<AiClinicsMainPage />} />
                <Route path="/airesearch" element={<AiResearchMainPage />} />
                <Route path="/gdpro" element={<GdProMainPage />} />
            </Routes>
        </Router>
    );
}

export default RoutingPage;