import React, { useEffect, useState } from "react";
import ProductLandingHeader from "../CommonProductsPages/ProductLandingHeader";
import { getProductDetails } from "../CommonProductsPages/ProductRepo";
import savetime from '../../images/airesearch_feautures/savetime.png';
import accuracy from '../../images/airesearch_feautures/accuracy.png';
import dataSecurity from '../../images/airesearch_feautures/data_security.png';
import multiPlatoform from '../../images/airesearch_feautures/multi_platoforms.png';
import userFriendly from '../../images/airesearch_feautures/user_friendly.png';
import androidApkLogo from '../../images/download_logos/android-apk-white.png';
import playStoreLogo from '../../images/download_logos/playstore-white.png';
import appStoreLogo from '../../images/download_logos/appstore-white.png';
import webLogo from '../../images/download_logos/webpage-white.png';
import ProductLoadingPage from "../CommonProductsPages/ProductLoadingPage";
import ProductHeader from "../CommonProductsPages/ProductHeader";
import SummaryPage from "../CommonProductsPages/SummaryPage";
import PrivacyPolicy from "../CommonProductsPages/PrivacyPolicy";
import Footer from "../../HomePage/Footer";
import UnavailableAlert from "../CommonProductsPages/UnavailableAlert";

function AiResearchMainPage() {

    const [productLoading, setProductLoading] = useState(false);
    const [product, setProductData] = useState({});
    const [navOpen, setNavOpen] = useState(false);

    const toggleNav = () => {
        setNavOpen(!navOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setNavOpen(false);
            }
        };
        console.log(window.innerWidth);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const sections = [
        {
            id: '#productHome',
            sectionName: 'Home'
        },
        {
            id: '#feautures',
            sectionName: 'Feautures'
        },
        {
            id: '#whyUs',
            sectionName: 'Why Us'
        },
        {
            id: '#howToAcess',
            sectionName: 'How To Use'
        },
        {
            id: '#download',
            sectionName: 'Download'
        },
        {
            id: '#pricing',
            sectionName: 'Plans'
        },
        {
            id: '#privacy',
            sectionName: 'Privacy Policy'
        },
    ]

    const getProduct = () => {
        getProductDetails({
            productId: 2,
            setProductLoading,
            setProduct: setProductData,
        });
    };

    useEffect(() => { getProduct() }, [])

    if (productLoading) {
        return (
            <ProductLoadingPage />
        );
    }

    return (
        <div
            className="product-description-div"
            style={{
                "--background-image": `url(${product.imageUrl})`
            }}
        >
            <ProductHeader
                toggleNav={toggleNav}
                navOpen={navOpen}
                sections={sections}
            />
            <ProductLandingHeader
                product={product}
            />

            <SummaryPage
                summary='Ai Research is an advanced platform that leverages technology to transform the way researchers work.
                From automating tedious tasks to providing intelligent insights, Ai Research empowers researchers to focus on what truly
                matters – Innovation and Discovery.'
            />

            <div id='feautures' className="section-heading">Built for Researchers, Designed for Excellence</div>
            <div className="features-section">
                <div className="feature-card">
                    <h3>Consult with Experts</h3>
                    <p>Collaborate with experienced research consultants to design and refine your study plans effectively.</p>
                </div>
                <div className="feature-card">
                    <h3>Tailored Proformas</h3>
                    <p>Create and digitize proformas uniquely customized to meet the specific needs of your research study.</p>
                </div>
                <div className="feature-card">
                    <h3>Secure Cloud Data Storage</h3>
                    <p>Safeguard your data with encrypted cloud storage, enabling seamless access from any location at any time.</p>
                </div>
                <div className="feature-card">
                    <h3>Real-Time Analysis & Insights</h3>
                    <p>Transform raw data into actionable insights with live, automated analysis and professional-grade visualizations.</p>
                </div>
                <div className="feature-card">
                    <h3>Multi-Center Research Simplified</h3>
                    <p>Effortlessly manage multi-center studies with our cloud-based data integration and collaboration tools.</p>
                </div>
                <div className="feature-card">
                    <h3>Role-Based Access Control</h3>
                    <p>Ensure data security with customizable user-based access levels for different application features.</p>
                </div>
                <div className="feature-card">
                    <h3>Effortless Bibliography Management</h3>
                    <p>Streamline your referencing process with tools to create, organize, and export bibliographies with ease.</p>
                </div>
                <div className="feature-card">
                    <h3>Digital Consent Management</h3>
                    <p>Digitally capture, organize, and manage all consent forms and permissions efficiently.</p>
                </div>
                <div className="feature-card">
                    <h3>Organized Subject Files</h3>
                    <p>Maintain all subject-related files in a structured and easily retrievable manner, ensuring better data management.</p>
                </div>
                <div className="feature-card">
                    <h3>AI-Powered Summarization</h3>
                    <p>Quickly generate concise summaries of lengthy research articles with our cutting-edge summarization engine.</p>
                </div>
            </div>

            <div id='whyUs' className="section-heading">Why Choose Us?</div>
            <div className="features-vertical">
                <div className="feature-section dark">
                    <div className="feature-content">
                        <h2>Time-Saving Efficiency</h2>
                        <p>Automate repetitive tasks and dedicate more time to your research.</p>
                    </div>
                    <div className="feature-image">
                        <img src={savetime} alt="Time-Saving Efficiency" />
                    </div>
                </div>

                <div className="feature-section light">
                    <div className="feature-image">
                        <img src={accuracy} alt="Unmatched Accuracy" />
                    </div>
                    <div className="feature-content">
                        <h2>Accuracy</h2>
                        <p>Achieve precision in your research with expert guidance and technology-driven insights tailored to your needs.</p>
                    </div>
                </div>

                <div className="feature-section dark">
                    <div className="feature-content">
                        <h2>User-Friendly Interface</h2>
                        <p>Intuitive and easy-to-navigate design for researchers at all levels.</p>
                    </div>
                    <div className="feature-image">
                        <img src={userFriendly} alt="User-Friendly Interface" />
                    </div>
                </div>

                <div className="feature-section light">
                    <div className="feature-image">
                        <img src={dataSecurity} alt="Secure Data Handling" />
                    </div>
                    <div className="feature-content">
                        <h2>Secure Data Handling</h2>
                        <p>Your research data is encrypted and securely stored.</p>
                    </div>
                </div>

                <div className="feature-section dark">
                    <div className="feature-content">
                        <h2>Multi-Platform Availability</h2>
                        <p>Available on Android, iOS, and Web.</p>
                    </div>
                    <div className="feature-image">
                        <img src={multiPlatoform} alt="Multi-Platform Availability" />
                    </div>
                </div>
            </div>

            <div id='howToAcess' className="section-heading">Your Path to Research Excellence</div>
            <div className="workflow-section">
                <div className="workflow-step">
                    <div className="step-icon">1</div>
                    <h3>Submit Your Request</h3>
                    <p>Provide a brief overview of your study and its requirements.</p>
                    <br />
                    <a
                        href="https://research.ainetwork.in/"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'blue' }}
                    >Click Here to Submit</a>
                </div>
                <div className="workflow-step">
                    <div className="step-icon">2</div>
                    <h3>Consult with Our Experts</h3>
                    <p>Engage in detailed discussions with our research consultants to finalize study proforma.</p>
                </div>
                <div className="workflow-step">
                    <div className="step-icon">3</div>
                    <h3>Finalize Proforma</h3>
                    <p>Collaborate with our team to create a tailored and finalized proforma for your study.</p>
                </div>
                <div className="workflow-step">
                    <div className="step-icon">4</div>
                    <h3>Select Your Subscription Plan</h3>
                    <p>Choose a subscription plan that aligns with your study's timeline, then proceed with payment.</p>
                </div>
                <div className="workflow-step">
                    <div className="step-icon">5</div>
                    <h3>Create Your Study</h3>
                    <p>Work with technical assistance to set up your study and digitize the finalized proforma.</p>
                </div>
                <div className="workflow-step">
                    <div className="step-icon">6</div>
                    <h3>Upload Data Seamlessly</h3>
                    <p>Start uploading your data anytime, from anywhere, with ease and security.</p>
                </div>
                <div className="workflow-step">
                    <div className="step-icon">7</div>
                    <h3>Access Live Results</h3>
                    <p>Once a sample size of over 20 subjects is achieved, live results and analytics are enabled for your study.</p>
                </div>
            </div>

            <div id='download' className="section-heading">Explore Ai Research Today</div>
            <div className="download-app-logos">

                <a href={product.androidLink} download target="_blank" rel="noopener noreferrer">
                    <div className="logo-container">
                        <img src={androidApkLogo} alt="Android" />
                    </div>
                </a>

                {product.playstoreLink ? (
                    <a href={product.playstoreLink} target="_blank" rel="noopener noreferrer">
                        <div className="logo-container">
                            <img src={playStoreLogo} alt="Playstore" />
                        </div>
                    </a>
                ) : (
                    <div className="logo-container" onClick={() => UnavailableAlert("Play Store")}>
                        <img src={playStoreLogo} alt="Playstore" />
                    </div>
                )}

                {product.iosLink ? (
                    <a href={product.iosLink} target="_blank" rel="noopener noreferrer">
                        <div className="logo-container">
                            <img src={appStoreLogo} alt="iOS" />
                        </div>
                    </a>
                ) : (
                    <div className="logo-container" onClick={() => UnavailableAlert("App Store")}>
                        <img src={appStoreLogo} alt="App Store" />
                    </div>
                )}

                <a href={product.webLink} target="_blank" rel="noopener noreferrer">
                    <div className="logo-container">
                        <img src={webLogo} alt="Web" />
                    </div>
                </a>
            </div>

            <div id='pricing' className="section-heading">Affordable Solutions, Unmatched Value</div>
            <div className="pricing-section">
                <div className="pricing-card">
                    <h3>Individual Plan</h3>
                    <p className="price">₹ 9999<span> /study</span></p>
                    <ul>
                        <li>Custom proforma</li>
                        <li>Live Results</li>
                        <li>File management <strong>1 GB</strong> storage space</li>
                        <li>Study valid for 1 year <strong>Renewal @ 1999/year</strong></li>
                    </ul>
                    <a href={product.webLink} target="_blank" rel="noopener noreferrer">
                        <button className="choose-plan-btn">Get Started</button>
                    </a>
                </div>

                {/* <div className="pricing-card featured">
                    <h3>Ai Research Plan</h3>
                    <p className="price">₹ 12999<span> /study</span></p>
                    <ul>
                        <li>Custom proforma</li>
                        <li>Live Results</li>
                        <li>File management <strong>2 GB</strong> storage space</li>
                        <li>AI Support for you research</li>
                    </ul>
                    <button className="choose-plan-btn">Choose Pro</button>
                </div> */}

                <div className="pricing-card">
                    <h3>Institute Plan</h3>
                    <p className="price">Custom Pricing</p>
                    <ul>
                        <li>Tailored solutions for institutes and universities</li>
                        <li>Dedicated support</li>
                    </ul>
                    <a href="#get-in-touch">
                        <button className="choose-plan-btn">Contact Us</button>
                    </a>

                </div>
            </div>

            <div className="product-cta-section">
                <h2>Ready to take your research to the next level?</h2>
                <p>Join thousands of researchers who trust AI Research for their daily workflows.</p>
                <div className="product-cta-buttons">
                    <a
                        href="https://research.ainetwork.in"
                        className="product-cta-button primary"
                        target="_blank" rel="noopener noreferrer"
                    >Get Started</a>
                </div>
            </div>

            <PrivacyPolicy
                objectKey='Company Products/Ai Research/privacy_policy.json'
            />

            <Footer />

        </div>
    );
}

export default AiResearchMainPage;