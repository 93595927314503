import axios from "axios";

export async function getProductDetails(
    { productId, setProductLoading, setProduct }) {
    setProductLoading(true);
    const productUrl =
        `https://devresearch.ainetwork.in:3007/api/anjani/getAnjaniProduct/${productId}`;

    try {
        const result = await axios.get(productUrl);
        setProduct(result.data.data);
        console.log(result.data);
    } catch (error) {
        console.error("Error fetching product:", error);
    } finally {
        setProductLoading(false);
    }
}

export async function getProductPrivacyPolicy({
    objectKey,
    setPrivacyLoading,
    setPrivacyProduct,
}) {

    const apiUrl = `https://devresearch.ainetwork.in:3007/api/awsservice/downloadFile`;


    setPrivacyLoading(true);

    try {

        // Fetch the file as a binary blob
        const result = await axios.post(apiUrl, {
            fileUrl: objectKey,
        }, {
            responseType: 'blob',
        });

        
        const fileText = await result.data.text();
        const parsedData = JSON.parse(fileText); 

        setPrivacyProduct(parsedData); 
    } catch (error) {

        console.error("Error fetching privacy policy:", error.message);


        setPrivacyProduct(null);
    } finally {

        setPrivacyLoading(false);
    }
}
