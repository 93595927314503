import React from "react";
import { Link } from "react-router-dom";
import logo from '../../images/Anjani Innovations Logo-1.png';

function ProductHeader({ toggleNav, navOpen, sections }) {

    const scrollToSection = (targetSection) => {
        toggleNav();
        const targetElement = document.querySelector(`#${targetSection}`);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <header className="product-header">
            <Link to="/" className="logo-link">
                <div className="logo">
                    <img className="logo-image" src={logo} alt="Ai Network Logo" />
                    <span>Ai Network</span>
                </div>
            </Link>
            <div>
                <nav className={`nav ${navOpen ? 'open' : ''}`}>
                    {
                        sections.map((section) => (
                            <a href={section.id} onClick={toggleNav}>{section.sectionName}</a>
                        ))
                    }
                    <button className="cta-btn" onClick={() => scrollToSection('get-in-touch')}>Get in Touch</button>
                </nav>
            </div>

            <div className={`hamburger ${navOpen ? 'open' : ''}`} onClick={toggleNav}>
                &#9776;
            </div>
        </header>

    );
}

export default ProductHeader;