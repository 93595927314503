import React, { useEffect } from 'react';
import { useState } from 'react';
import Footer from './Footer';
import Header from './Header';
import Technologies from './Technologies';
import ProductList from './ProductsList';
import AboutUs from './AboutUs';

const HomePage = () => {

    const [navOpen, setNavOpen] = useState(false);

    const toggleNav = () => {
        setNavOpen(!navOpen);
    };

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth > 768) {
                setNavOpen(false);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const scrollLeftButton = document.querySelector('.scroll-left');
        const scrollRightButton = document.querySelector('.scroll-right');
        const techGrid = document.querySelector('.tech-grid');

        // Ensure that the elements exist before adding event listeners
        if (scrollLeftButton && scrollRightButton && techGrid) {
            scrollLeftButton.addEventListener('click', () => {
                techGrid.scrollBy({
                    left: -200, // Adjust this value
                    behavior: 'smooth'
                });
            });

            scrollRightButton.addEventListener('click', () => {
                techGrid.scrollBy({
                    left: 200, // Adjust this value
                    behavior: 'smooth'
                });
            });
        }

        // Cleanup the event listeners on component unmount
        return () => {
            if (scrollLeftButton && scrollRightButton) {
                scrollLeftButton.removeEventListener('click', null);
                scrollRightButton.removeEventListener('click', null);
            }
        };
    }, []);

    const scrollToSection = (targetSection) => {
        const targetElement = document.querySelector(`#${targetSection}`);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <div className="homepage">

            {/* Header */}
            <Header
                toggleNav={toggleNav}
                navOpen={navOpen}
            />

            {/* Hero Section */}
            <section className="hero">
                <h1>Empowering Innovation Through Technology</h1>
                <div className="hero-buttons">
                    <button className="primary-btn" onClick={() => scrollToSection('products')}>Explore Our Solutions</button>
                    {/* <button className="secondary-btn">Request a Demo</button> */}
                </div>
            </section>

            {/* About Us Snapshot */}
            <AboutUs />

            {/* Products Overview */}
            <ProductList />

            <Technologies />

            {/* Testimonials/Clients */}
            {/* <section className="testimonials">
                <h2>Trusted by Leading Businesses</h2>
                <div className="testimonial-card">
                    <p>"Ai Network has transformed the way we manage our clinics."</p>
                    <p>- Client Name, Company</p>
                </div>
            </section> */}

            {/* Call to Action */}
            <section className="cta-banner">
                <h2>Ready to Transform Your Ideas into Software?</h2>
                <h2 style={{ color: '#00C4B3' }}>Join Us!</h2>
                {/* <div className="hero-buttons">
                    <button className="primary-btn">Contact Us</button>
                    <button className="secondary-btn">Schedule a Demo</button>
                </div> */}
            </section>

            {/* Footer */}
            <Footer />
        </div>
    );
};

export default HomePage;
