import React from "react";
import logo from '../../images/Anjani Innovations Logo-1.png';

function ProductLoadingPage() {
    return (
        <div className="product-loading-div">
            <div className="loading-ring">
                <img src={logo} alt="Loading Logo" className="loading-logo" />
            </div>
            <p className="loading-text">Loading...</p>
        </div>
    );
}

export default ProductLoadingPage;
