import React from "react";
import innovation from '../images/aboutus/innovation.png';
import collaboration from '../images/aboutus/collaboration.png';
import excellence from '../images/aboutus/excellence.png'
import trust from '../images/aboutus/trust.png'

function AboutUs() {
    return (
        <div className="about-us-section" id="about">
            {/* Introduction */}
            <div className="about-us-intro">
                <h2 className="about-us-heading">Who We Are</h2>
                <p className="about-us-text">
                    At Anjani Innovations, we believe in turning ideas into transformative solutions. With a focus on
                    Artificial Intelligence, software development, and research tools, our journey is driven by passion, precision,
                    and a commitment to innovation.
                </p>
            </div>

            {/* Core Values */}
            <div className="core-values">
                <h3 className="core-values-heading">Our Core Values</h3>
                <div className="core-values-cards">
                    <div
                        className="core-value-card"
                        style={{ backgroundImage: `url(${innovation})` }}
                    >
                        <h4 style={{ color: '#6ec6cb' }}>Innovation</h4>
                        <p>Challenging the status quo with groundbreaking ideas.</p>
                    </div>
                    <div
                        className="core-value-card"
                        style={{ backgroundImage: `url(${collaboration})` }}
                    >
                        <h4 style={{ color: 'aliceblue' }}>Collaboration</h4>
                        <p>Building strong partnerships for impactful results.</p>
                    </div>
                    <div
                        className="core-value-card"
                        style={{ backgroundImage: `url(${trust})` }}
                    >
                        <h4 style={{ color: 'aliceblue' }}>Trust</h4>
                        <p>Delivering reliable solutions tailored to your needs.</p>
                    </div>
                    <div
                        className="core-value-card"
                        style={{ backgroundImage: `url(${excellence})` }}
                    >
                        <h4 style={{ color: '#f8c91b' }}>Excellence</h4>
                        <p>Ensuring quality and precision in every project.</p>
                    </div>
                </div>

            </div>

            {/* Call to Action */}
            <div className="about-us-cta">
                <p>Are you ready to embark on this journey with us?</p>
                <a href="#get-in-touch" className="cta-button">Get in Touch</a>
            </div>
        </div>
    );
}

export default AboutUs;
