import React, { useEffect, useState } from "react";
import androidApkLogo from '../../images/download_logos/android-apk.png';
import playStoreLogo from '../../images/download_logos/playstore.png';
import windowsLogo from '../../images/download_logos/windows.png';
import appStoreLogo from '../../images/download_logos/appstore.png';
import linuxLogo from '../../images/download_logos/linux.png';
import macOsLogo from '../../images/download_logos/macos.png';
import webLogo from '../../images/download_logos/webpage.png';
import downNavigateButton from '../../images/down-button.png';
import ComingSoon from "./ArrivingSoon";

function ProductLandingHeader({ product, launched=true, launchDate=null }) {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            setVisible(window.scrollY <= 100);
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, []);

    return (
        <div id='productHome' className={`product-logo-heading-detals ${visible ? "visible" : "hidden"}`}>
            {visible && (
                <header className="product-landing-header">
                    <img
                        className="product-logo"
                        src={product.imageUrl}
                        alt={product.productName}
                    />
                    <h1 className="product-title">{product.productName}</h1>
                    <h3 className="product-description">{product.productDescription}</h3>
                    {
                        launched ?
                            (
                                <>
                                    <div className="platform-logos">
                                        {product.androidLink && (
                                            <a href={product.androidLink} download target="_blank" rel="noopener noreferrer">
                                                <img src={androidApkLogo} alt="Android" />
                                            </a>
                                        )}
                                        {product.playstoreLink && (
                                            <a href={product.playstoreLink} target="_blank" rel="noopener noreferrer">
                                                <img src={playStoreLogo} alt="Playstore" />
                                            </a>
                                        )}
                                        {product.iosLink && (
                                            <a href={product.iosLink} target="_blank" rel="noopener noreferrer">
                                                <img src={appStoreLogo} alt="iOS" />
                                            </a>
                                        )}
                                        {product.windowsLink && (
                                            <a href={product.windowsLink} download target="_blank" rel="noopener noreferrer">
                                                <img src={windowsLogo} alt="Windows" />
                                            </a>
                                        )}
                                        {product.macOsLink && (
                                            <a href={product.macOsLink} download target="_blank" rel="noopener noreferrer">
                                                <img src={macOsLogo} alt="Mac Os" />
                                            </a>
                                        )}
                                        {product.linuxLink && (
                                            <a href={product.linuxLink} download target="_blank" rel="noopener noreferrer">
                                                <img src={linuxLogo} alt="Linux" />
                                            </a>
                                        )}
                                        {product.webLink && (
                                            <a href={product.webLink} target="_blank" rel="noopener noreferrer">
                                                <img src={webLogo} alt="Web" />
                                            </a>
                                        )}
                                    </div>
                                    <a
                                        href="#summary-section"
                                        className="navigate-down-button"
                                    >
                                        <img
                                            src={downNavigateButton}
                                            alt="Navigate Down"
                                        />
                                    </a>
                                </>
                            ) :
                            <ComingSoon 
                                launchDate={launchDate}
                            />
                    }
                    
                </header>
            )}
        </div>
    );
}

export default ProductLandingHeader;
