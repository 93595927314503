import React, { useEffect, useState } from "react";
import { getProductPrivacyPolicy } from "./ProductRepo";

function PrivacyPolicy({ objectKey }) {
    const [policyContent, setPolicyContent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchPrivacyPolicy = () => {
        getProductPrivacyPolicy({
            objectKey,
            setPrivacyLoading: setLoading,
            setPrivacyProduct: setPolicyContent
        })
    }

    useEffect(() => {
        fetchPrivacyPolicy();
    }, []);

    if (loading) return <p>Loading privacy policy...</p>;
    if (error) return <p>Error loading privacy policy: {error}</p>;

    return (
        <div id='privacy' className="privacy-policy">
            <div className="section-heading">Privacy Policy</div>
            <div className="policy-content">
                {policyContent &&
                    Object.entries(policyContent).map(([section, content], index) => (
                        <div key={index} className="policy-section">
                            <h3>{section}</h3>
                            <p>
                                {content.split("\n").map((paragraph, i) => (
                                    <span key={i}>
                                        {paragraph}
                                        <br />
                                    </span>
                                ))}
                            </p>
                        </div>
                    ))}
            </div>
        </div>
    );
}

export default PrivacyPolicy;
