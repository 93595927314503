import axios from "axios";
import React, { useState } from "react";
import { FaMapMarkerAlt, FaEnvelope, FaPhone, FaWhatsapp } from 'react-icons/fa';
import Swal from "sweetalert2";
import logo from '../images/Anjani Innovations Logo-1.png';

function Footer() {

    const [uploadingRequest, setUploadingRequest] = useState(false);
    const [formData, setFormData] = useState({
        requestPersonName: '',
        requestEmail: '',
        requestPhone: '',
        requestMessage: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        // Custom email validation
        if (name === "requestEmail") {
            const emailField = e.target;
            const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Basic email pattern

            if (!emailPattern.test(value)) {
                emailField.setCustomValidity("Please enter a valid email address.");
            } else {
                emailField.setCustomValidity(""); // Clear any previous error message
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Check for empty required fields
        if (!formData.requestPersonName || !formData.requestEmail || !formData.requestMessage) {
            Swal.fire({
                title: 'Incomplete Form',
                text: 'Please fill out all required fields marked with an asterisk (*).',
                icon: 'warning',
                confirmButtonColor: '#3085d6',
                confirmButtonText: 'Okay'
            });
            return;
        }

        setUploadingRequest(true);

        const uploadUrl = 'https://devresearch.ainetwork.in:3007/api/anjaniRequests/saveUpadte';

        try {
            const response = await axios.post(uploadUrl, formData);

            if (response.status === 200) {
                // Show success alert with logo
                Swal.fire({
                    title: 'Request Sent!',
                    text: 'Your request has been successfully sent!',
                    imageUrl: logo,
                    imageWidth: 150,
                    imageHeight: 150,
                    imageAlt: 'Anjani Innovations',
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'Okay'
                });

                // Clear form data after success
                setFormData({
                    requestPersonName: '',
                    requestEmail: '',
                    requestPhone: '',
                    requestMessage: ''
                });
            }

        } catch (error) {
            // Show error alert with logo
            Swal.fire({
                title: 'Oops...',
                text: 'There was an error sending your request. Please try again.',
                imageUrl: logo,
                imageWidth: 150,
                imageHeight: 150,
                imageAlt: 'Anjani Innovations',
                confirmButtonColor: '#d33',
                confirmButtonText: 'Ok'
            });
        } finally {
            setUploadingRequest(false);
        }
    };

    return (
        <footer className="footer" id='get-in-touch'>

            {/* Left Column: Contact Form */}
            <div className="footer-form">
                <h3>Get in Touch</h3>
                <form onSubmit={handleSubmit}>
                    <label htmlFor="requestPersonName">Name</label>
                    <input
                        type="text"
                        id="requestPersonName"
                        name="requestPersonName"
                        value={formData.requestPersonName}
                        onChange={handleChange}
                        required
                        placeholder="Your Name*"
                    />

                    <label htmlFor="requestEmail">Email</label>
                    <input
                        type="email"
                        id="requestEmail"
                        name="requestEmail"
                        value={formData.requestEmail}
                        onChange={handleChange}
                        required
                        placeholder="Your Email*"
                    />

                    <label htmlFor="requestPhone">Phone (optional)</label>
                    <input
                        type="tel"
                        id="requestPhone"
                        name="requestPhone"
                        value={formData.requestPhone}
                        onChange={handleChange}
                        placeholder="Your Phone Number"
                    />

                    <label htmlFor="requestMessage">Message</label>
                    <textarea
                        id="requestMessage"
                        name="requestMessage"
                        rows="3"
                        value={formData.requestMessage}
                        onChange={handleChange}
                        required
                        placeholder="Leave a Message*"
                    ></textarea>

                    {
                        uploadingRequest ? (
                            <button type="button" className="submit-btn" disabled>
                                <div className="spinner"></div>
                            </button>
                        ) : (
                            <button type="button" className="submit-btn" onClick={handleSubmit}>Send Request</button>
                        )
                    }

                    <small>Your information is safe with us. We won't share with any third parties.</small>
                </form>
            </div>

            {/* Divider */}
            <div className="footer-divider"></div>

            {/* Right Column: Address and Contact Details */}
            <div className="footer-details">

                <div className="footer-address-mail-phone">
                    {/* Address Section */}
                    <div
                        className="contact-item"
                    // style={{ flexDirection: 'column', alignItems: 'center' }}
                    >
                        <FaMapMarkerAlt className="contact-icon" />
                        <address>
                            First Floor, Sri Laxmi Nilayam, Plot no 404,<br />
                            Road No 16, Agriculture Colony, Hastinapuram, <br />
                            Hyderabad, Telangana, India - 500 079
                        </address>
                    </div>

                    <div className="footer-mail-phone">
                        {/* Email Section */}
                        <div className="contact-item">
                            <FaEnvelope className="contact-icon" />
                            <p>mail@ainetwork.in</p>
                        </div>

                        {/* Phone Section */}
                        <div className="contact-item">
                            <FaPhone className="contact-icon" />
                            <p>+91 83419 89192</p>
                        </div>

                        {/* Phone Section */}
                        <div className="contact-item">
                            <FaWhatsapp className="contact-icon" />
                            <p>+91 77948 79192</p>
                        </div>
                    </div>

                </div>

                {/* Social Media Links */}
                {/* <div className="social-media">
                    <a href="#linkedin">LinkedIn</a>
                    <a href="#twitter">Twitter</a>
                </div> */}
            </div>
        </footer>
    );
}

export default Footer;