import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa"; // Import icons for scroll buttons
import reactLogo from '../images/tech_logos/react_logo.png';
import flutterLogo from '../images/tech_logos/flutter-logo.jpg';
import nodeJsLogo from '../images/tech_logos/nodejs_logo.webp';
import pythonLogo from '../images/tech_logos/python-logo.png';
import angularLogo from '../images/tech_logos/angular_logo.png';
import awsLogo from '../images/tech_logos/aws.png';
import dotnetLogo from '../images/tech_logos/dotnet_core.png';
import msSqlLogo from '../images/tech_logos/ms_sql_logo.png';
import mySqlLogo from '../images/tech_logos/mysql_logo.jpg';
import mongoDbLogo from '../images/tech_logos/mongo_db.png';

function Technologies() {
    return (
        <section className="technologies" id="technologies">
            <h2>Technologies We Work With</h2>
            <div className="tech-carousel">
                <button className="scroll-left"><FaChevronLeft /></button>
                <div className="tech-grid">
                    <div className="tech-item">
                        <img src={reactLogo} alt="React" />
                        <p>React</p>
                    </div>
                    <div className="tech-item">
                        <img src={angularLogo} alt="Angular" />
                        <p>Angular</p>
                    </div>
                    <div className="tech-item">
                        <img src={flutterLogo} alt="Flutter" />
                        <p>Flutter</p>
                    </div>
                    <div className="tech-item">
                        <img src={nodeJsLogo} alt="Node.js" />
                        <p>Node.js</p>
                    </div>
                    <div className="tech-item">
                        <img src={pythonLogo} alt="Python" />
                        <p>Python</p>
                    </div>
                    <div className="tech-item">
                        <img src={awsLogo} alt="AWS" />
                        <p>AWS</p>
                    </div>
                    <div className="tech-item">
                        <img src={dotnetLogo} alt="DotNet" />
                        <p>.Net Microservices</p>
                    </div>
                    <div className="tech-item">
                        <img src={msSqlLogo} alt="MS SQL" />
                        <p>MS SQL</p>
                    </div>
                    <div className="tech-item">
                        <img src={mySqlLogo} alt="MySQL" />
                        <p>MySQL</p>
                    </div>
                    <div className="tech-item">
                        <img src={mongoDbLogo} alt="MongoDB" />
                        <p>MongoDB</p>
                    </div>
                </div>
                <button className="scroll-right"><FaChevronRight /></button>
            </div>
        </section>
    );
}

export default Technologies;
