import React from "react";
import { Link } from 'react-router-dom';
import logo from '../images/Anjani Innovations Logo-1.png';


function Header({ toggleNav, navOpen }) {

    const scrollToSection = (targetSection) => {
        toggleNav();
        const targetElement = document.querySelector(`#${targetSection}`);
        if (targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <header className="header">
            <Link to="/" className="logo-link">
                <div className="logo">
                    <img className="logo-image" src={logo} alt="Ai Network Logo" />
                    <span>Ai Network</span>
                </div>
            </Link>
            <div>
                <nav className={`nav ${navOpen ? 'open' : ''}`}>
                    <a href="#home" onClick={toggleNav}>Home</a>
                    <a href="#about" onClick={toggleNav}>About Us</a>
                    <a href="#products" onClick={toggleNav}>Products</a>
                    <a href="#technologies" onClick={toggleNav}>Technologies</a>
                    <button className="cta-btn" onClick={() => scrollToSection('get-in-touch')}>Get in Touch</button>
                </nav>
            </div>

            <div className={`hamburger ${navOpen ? 'open' : ''}`} onClick={toggleNav}>
                &#9776;
            </div>
        </header>
    );
}

export default Header;