import React from "react";

function SummaryPage({ summary }) {
    return (
        <div id="summary-page" className="summary-page">
            <div id="summary-section" className="summary-section">
                <h2>{summary}</h2>
            </div>
        </div>
    );
}

export default SummaryPage;