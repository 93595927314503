import React, { useState, useEffect } from 'react';

function ComingSoon({ launchDate }) {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    function calculateTimeLeft() {
        const now = new Date();
        const difference = new Date(launchDate) - now;

        return {
            days: Math.max(0, Math.floor(difference / (1000 * 60 * 60 * 24))),
            hours: Math.max(0, Math.floor((difference / (1000 * 60 * 60)) % 24)),
            minutes: Math.max(0, Math.floor((difference / (1000 * 60)) % 60)),
            seconds: Math.max(0, Math.floor((difference / 1000) % 60)),
        };
    }

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, [launchDate]);

    return (
        <div className="coming-soon-container">
            <h1 className="coming-soon-title">Launching on {new Date(launchDate).toDateString()}</h1>
            <div className="countdown-timer">
                <div className="time-box">
                    <h2>{timeLeft.days}</h2>
                    <p>Days</p>
                </div>
                <div className="time-box">
                    <h2>{timeLeft.hours}</h2>
                    <p>Hours</p>
                </div>
                <div className="time-box">
                    <h2>{timeLeft.minutes}</h2>
                    <p>Minutes</p>
                </div>
                <div className="time-box">
                    <h2>{timeLeft.seconds}</h2>
                    <p>Seconds</p>
                </div>
            </div>
        </div>
    );
}

export default ComingSoon;
