import Swal from 'sweetalert2';

function UnavailableAlert(platform) {
    Swal.fire({
        title: `${platform} Availability`,
        text: `This product will be arriving soon in ${platform}. Stay tuned!`,
        icon: 'info',
        confirmButtonText: 'Okay',
        confirmButtonColor: '#3085d6',
    });
}

export default UnavailableAlert;
